import React, { useEffect, useState } from "react";
// import axios from "axios";
import socketIO, { io } from "socket.io-client";
export const AuthContext = React.createContext(null);

const AuthProvider = ({ children }) => {
  const [pageRefresh, setPageRefresh] = React.useState(false);

  const user = JSON.parse(localStorage.getItem("bbb_admin"));

  const [socketId, setSocketId] = React.useState("");
  const [msgList, setMsgList] = React.useState([]);
  console.log("msgList:", msgList);
  const [cRoomId, setCRoomId] = useState("");
  const [ringData, setRingData] = useState({});

  const ENDPOINT = "https://yeapbe.com:3500/";
  let socket;
  socket = io(ENDPOINT, {
    transports: ["websocket"],
  });
  console.log("socket-821:", socket);

  //socket implement
  useEffect(() => {
    setCRoomId(user?.user_company?.[0]?.item_refer);
  }, [user?.user_company?.[0]?.item_refer]);
  useEffect(() => {
    console.log("receiveResult-11111131", socketId);
    // socket.on("newmsg", (datacc)=> {
    //   console.log("receiveResult-1111113", datacc);
    //   const perseData = JSON.parse(datacc);
    //   console.log("receiveResult-11112", perseData);

    //   setMsgList([...msgList, perseData?.msgData?.[0]]);
    // });

    if (cRoomId) {
      socket.on("connect", () => {
        // alert("connected successfully with socket");
        setSocketId(socket.id);
        console.log(
          "receiveResult-110000",
          "connected successfully with socket" + socket.id
        );
      });
      // setJoinFunc(cRoomId);
      socket.emit("join", cRoomId);
      console.log("socket_join_", cRoomId);
      console.log("socket_jid_", socket.id);
    }

    return () => {
      socket.on("disconnect");
      socket.off();
    };
  }, [cRoomId]);
  // }, [msgList, socket, cRoomId]);

  socket.on("newCall", (datacc) => {
    console.log("receiveResult-1111113", datacc);
    const perseData = JSON.parse(datacc);
    console.log("receiveCall-11112", perseData);
    console.log("receiveCall-newuio", perseData);
    setRingData(perseData);
    setMsgList([...msgList, perseData?.msgData?.[0]]);
  });

  return (
    <AuthContext.Provider
      value={{
        pageRefresh,
        setPageRefresh,
        socket,
        user,
        ringData,
        setRingData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default React.memo(AuthProvider);
