import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter } from "react-router-dom";
import React, { Suspense } from "react";
// import { Spinner } from "react-bootstrap";
import AuthProvider from "./Context/AuthProvider";
import { Box } from "@mui/material";
import loading from "./Components/Vendors/Images/loading.gif";
import { useState } from "react";
import { createContext } from "react";
import StatusProvider from "./Context/StatusProvider";
const Routing = React.lazy(() => import("./Components/Routing/Routing.js"));
export const UserContext = createContext();
export const RenderContext = createContext();
export const MenuContext = createContext();
function App() {
  const [render, setRender] = useState(false);
  const [menuType, setMenuType] = useState("dashboard");

  return (
    <div>
      <MenuContext.Provider value={[menuType, setMenuType]}>
        <RenderContext.Provider value={[render, setRender]}>
          <StatusProvider>
            <AuthProvider>
              <Suspense
                fallback={
                  <Box
                    sx={{
                      height: "100vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img
                      src={loading}
                      alt="loading"
                      style={{
                        width: "200px",
                        height: "200px",
                      }}
                    />
                  </Box>
                }
              >
                <BrowserRouter>
                  <Routing />
                </BrowserRouter>
              </Suspense>
            </AuthProvider>
          </StatusProvider>
        </RenderContext.Provider>
      </MenuContext.Provider>
    </div>
  );
}

export default App;
